<template>
  <sign-page
    title-text="人员分组"
    :request="request"
    table-size="large"
    :title-menus="[{key: 'new', label: '新增'}]"
    :table-actions="[{key: 'update', label: '修改'}, {key: 'del', label: '删除'}]"
    @tableAction="tableAction"
    @clickTitleMenu="chooseData = {};modal = true;"
    :column-list="columnList"
    ref="ryfzPage"
    :tableActionsWidth="100"
    :table-actions-fixed="true">
    <fm-modal title="人员分组" :mask-closable="false" v-model="modal" width="800px" theme="mh-blackt">
      <fm-form style="margin:0 2rem 0 10px;" :inline="false" label-align="left">
        <fm-form-item label="分组名称">
          <fm-input-new v-model="chooseData.name" placeholder=""/>
        </fm-form-item>
        <fm-form-item label="分组人员">
          <user-choose :user-list="userList" v-model="chooseData.userIds" />
        </fm-form-item>
      </fm-form>
      <div class="m-btn">
        <fm-btn v-loadingx="saving" @click="save">保存</fm-btn>
        <fm-btn v-loadingx="saving" style="margin-left: 50px;" @click="modal = false">取消</fm-btn>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  sysConfigRequest as request
} from '@/api'

import UserChoose from '@/components/base/UserChoose'

export default {
  components: {
    UserChoose
  },
  computed: {
    userList () {
      return this.$store.getters.userList
    },
    columnList: {
      get () {
        let data = [{
          title: '分组名称',
          field: 'name',
          width: 100
        },
        {
          title: '人员明细',
          field: 'userNames',
          width: 500
        }]
        return data
      }
    }
  },
  created () {
    this.$store.dispatch('loadUserGroup')
  },
  methods: {
    async save () {
      if (!this.chooseData.name) {
        this.$notice.info('系统提示', '请输入分组名称!')
        return
      }
      if (this.saving) {
        return
      }
      this.saving = true
      await request.add({
        type: 'userGroup',
        configKey: this.chooseData.configKey || String(new Date().getTime()),
        name: this.chooseData.name,
        value: this.chooseData.userIds && this.chooseData.userIds.length > 0 ? this.chooseData.userIds.join(',') : null,
      })
      this.$store.dispatch('loadUserGroup')
      this.$refs.ryfzPage.loadData()
      this.modal = false
      this.saving = false
    },
    tableAction (parm) {
      this.chooseData = Object.assign({}, parm.data)
      if (parm.action === 'update') {
        this.modal = true
      }
    },
    async loadData () {
      await this.$store.dispatch('loadUserList')
      let datas = await request.get({
        type: 'userGroup'
      })
      datas.forEach(v => {
        v.userIds = v.value ? v.value.split(',').map(v => Number(v)) : []
        v.userNames = this.userList.filter(v1 => v.userIds.includes(v1.data.id)).map(v2 => v2.data.name).join('，')
      })
      return datas
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      chooseData: {},
      modal: false,
      saving: false,
    }
  }
}
</script>

<style lang="less" scoped>
.m-btn {
  display: flex;
  justify-content: center;
}
</style>